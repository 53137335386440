body {
  text-align: center;
  background-color: #1c3490;
  margin: 0;
  font-family: Roboto Condensed;
}

html, body {
  background-color: #1c3490;
  margin: 0;
  font-size: 18px;
}

html, body, #app, #container {
  height: 100%;
  min-height: 100%;
  width: 100%;
}

a, a:visited {
  color: #fff;
}

#container {
  position: relative;
  height: auto !important;
}

#header {
  padding: 1rem;
}

#content {
  height: 600px;
  box-sizing: border-box;
  background-color: #fff;
  background-image: url("collage.d84a8d10.png");
  background-position: top;
  background-repeat: repeat-x;
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
  padding: 1rem;
}

#footer {
  width: 100%;
  position: absolute;
  bottom: 0 !important;
}

.overlay-content {
  width: 600px;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, .6);
  border-radius: 1rem;
  margin: 0 auto;
  padding: .5rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
}

hr {
  width: 70%;
}

.hours {
  margin: 0 .5rem;
}

.days {
  margin-left: .5rem;
}

@media screen and (max-device-width: 600px) {
  .overlay-content {
    width: auto;
  }
}

/*# sourceMappingURL=index.cde7586e.css.map */
