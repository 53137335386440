body {
    margin: 0;
    text-align: center; 
    font-family: 'Roboto Condensed';
    background-color: rgb(28, 52, 144);
}

html, body {
    margin: 0;
    font-size: 18px;
    background-color: rgb(28, 52, 144);
}

html, body, #app, #container {
    height: 100%;
    min-height: 100%;
    width: 100%;
}

a, a:visited {
    color: white;
}

#container {
    position: relative; 
    height: auto !important;  
}

#header {
    padding: 1rem;
}

#content {
    background-image: url(../static/collage.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-repeat: repeat-x;
    background-color: white;
    border-top: solid 4px white;
    border-bottom: solid 4px white;
    height: 600px;    
    padding: 1rem;
    box-sizing: border-box;
}

#footer {
    position: absolute;
    width: 100%;
    bottom: 0 !important; /* stick to bottom */
}

.overlay-content {
    width: 600px;
    margin: 0 auto;
    background-color: rgba(0,0,0,0.6);
    border-radius: 1rem;
    color: white;
    text-align: center;
    line-height: 1.8rem;
    font-size: 1.2rem;
    padding: 0.5rem;
}

hr {
    width: 70%;
}

.hours {
    margin: 0 0.5rem;
}

.days {
    margin-left: 0.5rem;
}

@media screen and (max-device-width: 600px) {

    .overlay-content {
        width: auto;
    }

}
